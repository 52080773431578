import React from "react"
import styled from "styled-components"

import Header from "../Components/Header.jsx"
import Company from "../Components/Company.jsx"
import Products from "../Components/Products.jsx"
import Footer from "../Components/Footer.jsx"

const Printex = styled.div`
  background-color: blue;
  width: '100%';
  height: '100%';
  margin: '0px auto';
  padding: '0px';
`

export default () => {
  return (
    <Printex>
      <Header />
      <Company />
      <Products />
      <Footer />
    </Printex>
  );
}