import React from "react"
import styled from "styled-components"

import {device} from '../css/CSSMediaQueries.js'

const CompanySection = styled.section`
  background-color: #222;
  padding: 50px;
  display: flex;
  justify-content: center;
`

const DescriptionDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 2em;
  @media ${device.tabletM} {
    grid-template-columns: 1fr;
  }
`

const DescriptionBox = styled.div`
  width: 200px;
  color: #ddd;
  > h4, p {
    text-align: center;
    margin: 0px;
  } 

  > h4 {
    margin-bottom: 20px;
    color: white;
  }
`

class Company extends React.Component {
  render() {
    return(
      <CompanySection>
        <DescriptionDiv>
          <DescriptionBox>
            <h4>Who we are</h4>
            <p>
              We offer a worldwide distribution of label printing systems and supplies.
            </p>
          </DescriptionBox>
          <DescriptionBox>
            <h4>Partners</h4>
            <p>
              Our systems are used at professional label producers and apparel manufacturers throughout the world.
            </p>
          </DescriptionBox>
          <DescriptionBox>
            <h4>Service</h4>
            <p>
              We quickly ship out light weight replacement modules for any need.
            </p>
          </DescriptionBox>
        </DescriptionDiv>
      </CompanySection>
    );
  }
}

export default Company;