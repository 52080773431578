import React from "react"
import styled from "styled-components"

import {device} from "../css/CSSMediaQueries.js"

import printexSplash from "../images/printex-splash.jpg"

const HeaderDiv = styled.header`
  background-color: black;
  position: relative;

  > h4, h5, h6 {
    color: white;
    margin: 0px;
  }
`

const PrintexSplashBackgroundImage = styled.div`
  height: 800px;
  background-image: url(${printexSplash});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: -1;
  opacity: 0.4;
`

const SplashPageContent = styled.div`
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
`

const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  justify-content: center;
  @media ${device.tabletM} {
    grid-template-columns: 1fr;
  }
`

const PrintexTitle = styled.div`
  > h1 {
    font-size: 50px
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
  }
`

const InnerDetails = styled.div`
  color: white;
  width: 260px;

  > div h4 {
    color: white;
    font-size: 25px;
    margin-bottom: 20px;
  }

  > div h6 {
    color: #ddd;
    line-height: 2;
  }

  @media ${device.tabletM} {
    width: auto;
  }
`

const ColumnOne = styled.div`
  text-align: left;
  width: 100%;
  @media ${device.tabletM} {
    text-align: center;
  }
`

const ColumnTwo = styled.div`
  text-align: center;
  @media ${device.tabletM} {
    text-align: center;
  }
`

const ColumnThree = styled.div`
  text-align: right;
  @media ${device.tabletM} {
    text-align: center;
  }
`

class Header extends React.Component {
  render() {
    return (
      <HeaderDiv>
        <PrintexSplashBackgroundImage></PrintexSplashBackgroundImage>
        <SplashPageContent>
          <PrintexTitle>
            <h1>Printex Technology</h1>
          </PrintexTitle>
          <GridHeader>
            <InnerDetails>
              <ColumnOne>
                <h4>Contact</h4>
                <h6>Email: Sales@alabel.com</h6>
                <h6>Phone: +1(626)286-7165</h6>
                <h6>Fax: +1(626)286-8065</h6>
              </ColumnOne>
            </InnerDetails>
            <InnerDetails>
              <ColumnTwo>
                <h4>Business Hours</h4>
                <h6>Monday - Friday: 8:00AM - 5:00PM</h6>
                <h6>Saturday and Sunday: Closed</h6>
              </ColumnTwo>
            </InnerDetails>
            <InnerDetails>
              <ColumnThree>
                <h4>Address</h4>
                <h6>324 S. San Gabriel Blvd.</h6>
                <h6>San Gabriel, CA 91776-1931</h6>
              </ColumnThree>
            </InnerDetails>
          </GridHeader>
        </SplashPageContent>
      </HeaderDiv>
    )
  }
}

export default Header;