import React from "react"
import styled from "styled-components"

import {device} from "../css/CSSMediaQueries.js"

const FooterSection = styled.footer`
  padding-top: 100px;
  padding-bottom: 100px;
  background-color: #222;
  display: flex;
  justify-content: center;
  align-items: center;
`

const FooterContent = styled.div`
  > h4, h5, h6 {
    color: white;
    margin: 0px;
  }
`

const GridHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1em;
  justify-content: center;

  > h6 {
    color: white;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  @media ${device.tabletM} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tabletS} {
    grid-template-columns: 1fr;
  }
`

const PrintexTitle = styled.div`
  > h1 {
    font-size: 50px
    margin-top: 0px;
    margin-bottom: 0px;
    color: white;
  }
`

const InnerDetails = styled.div`
  color: white;
  width: 250px;

  > h4 {
    color: white;
    font-size: 25px;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  > h6 {
    color: #ddd;
    line-height: 2;
  }

  // @media ${device.tabletM} {
  //   width: 250px;
  // }
`

class Footer extends React.Component {
  render() {
    return(
      <FooterSection>
        <FooterContent>
          <GridHeader>
            <InnerDetails>
              <h4>Contact:</h4>
              <h6>Email: Sales@alabel.com</h6>
              <h6>Phone: +1(626)286-7165</h6>
              <h6>Fax: +1(626)286-8065</h6>
            </InnerDetails>
            <InnerDetails>
              <h4>Business Hours:</h4>
              <h6>Monday - Friday: 8:00AM - 5:00PM</h6>
              <h6>Saturday and Sunday: Closed</h6>
            </InnerDetails>
            <InnerDetails>
              <h4>Address:</h4>
              <h6>324 S. San Gabriel Blvd.</h6>
              <h6>San Gabriel, CA 91776-1931</h6>
            </InnerDetails>
            <h6>© 2018 Printex Technology.</h6>
          </GridHeader>
        </FooterContent>
      </FooterSection>
    )
  }
}

export default Footer;