import React from "react"
import styled from "styled-components"

import {device} from '../css/CSSMediaQueries.js'

import PT4Image from "../images/PT4.jpg"
import ColdCutterImage from "../images/ColdCutter.jpg"
import HotCutterImage from "../images/HotCutter.jpg"
import StackerImage from "../images/Stacker.jpg"
import T6Image from "../images/T6.jpg"
import DyeSubImage from "../images/DyeSub.jpg"
import SatoPrinterImage from "../images/SatoPrinter.jpg"


const ProductSection = styled.section`
  padding: 50px;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  > div h2 {
    margin-top: 0px;
  }
  @media ${device.tabletM} {
    padding: 0px;
  }
`

const InnerDiv = styled.div`
  width: 1000px;
  > div {
    text-align: left;
  }
`

const ProductEntries = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  // div:nth-child(1) {
  //   grid-column-start: 1; 
  //   grid-column-end: 3; 
  //   text-align: center;
  // }
  // div:nth-child(2) {
  //   grid-column-start: 3; 
  //   grid-column-end: 5; 
  //   text-align: center;
  // }
  // div:nth-child(3) {
  //   grid-column-start: 5; 
  //   grid-column-end: 7;
  //   text-align: center;
  // }

  @media ${device.tabletM} {
    grid-template-columns: 1fr 1fr;
  }

  @media ${device.tabletS} {
    grid-template-columns: 1fr;
  }
`

const ProductEntry = styled.div`
  padding: 20px;
  // box-shadow: 0px 0px 5px #777;

  // grid-column-end: span 2;

  > h3, img {
    margin: 0px;
  }

  > h4 {\
    text-align: center;
    margin-top: 5px;
  }
`

const PT4DescriptionDiv = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr;
  padding-top: 20px;
  padding-bottom: 20px;
`

const PT4ModuleTitle = styled.h3`
  text-align: center;
`

class Products extends React.Component {
  render() {
    return(
      <ProductSection>
        <InnerDiv>
          <h2>PT4</h2>
          <ProductEntry>
            <img src={PT4Image} />
            <PT4DescriptionDiv>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The PT4 is composed of several major parts (SATO printer, hot/cold cutter, stacker, & the main control base). Each part is self contained and can be swapped with ease. Additional modules include the T6 and Dye Sublimation. The Combination of all these parts make the PT4 unit. </p>
              <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;The operators have access to different label cutting lengths, cutting speeds, label counts, label stacking timing, cut history, a non-printer smart mode, and much more.</p>
            </PT4DescriptionDiv>
            <PT4ModuleTitle>PT4 Modules</PT4ModuleTitle>
          </ProductEntry>
          <ProductEntries>
            <ProductEntry>
              <img src={HotCutterImage} />
              <h4>Hot Cutter</h4>
            </ProductEntry>
            <ProductEntry>
              <img src={ColdCutterImage} />
              <h4>Cold Cutter</h4>
            </ProductEntry>
            <ProductEntry>
              <img src={StackerImage} />
              <h4>Straight Stacker</h4>
            </ProductEntry>
            <ProductEntry>
              <img src={T6Image} />
              <h4>T6</h4>
            </ProductEntry>
            <ProductEntry>
              <img src={DyeSubImage} />
              <h4>Dye Sublimation</h4>
            </ProductEntry>
            <ProductEntry>
              <img src={SatoPrinterImage} />
              <h4>SATO Printer</h4>
            </ProductEntry>
          </ProductEntries>
        </InnerDiv>
      </ProductSection>
    )
  }
}

export default Products;